:root {
    /* Navigation bar */
    --navbar-background-color: #363636; /* Dark pastel pink */
    --navbar-text-color: #eb77dc; /* Light pink */

    /* Content */
    --content-background-color: #fcf2f1; /* Pastel pink background */
    --content-text-color: #333333; /* Dark text color */
    --content-subtext-color: #555555; /* Light text color */
    --content-highlight-color: #ff69b4; /* Bright pink for the button */
    
    
    /* Card */
    --card-background-color: #ffffff; /* White for project cards */
    --card-shadow-color: rgba(0, 0, 0, 0.1); /* Shadow color */
  }

  body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
  }

