/* NavBar.css */
.header {
  background-color: var(--navbar-background-color);
  padding: 20px 0px;
  color: var(--navbar-text-color);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; /* Ensure the dropdown is positioned relative to the navbar */
}

.nav-links {
  list-style: none;
  padding: 0;
  display: flex;
}

.nav-links li {
  margin: 0 15px;
}

.nav-links a {
  color: var(--navbar-text-color);
  text-decoration: none;
}

.logo {
  color: inherit; /* Inherit color from parent */
  text-decoration: none; /* Remove underline */
}

.logo h1 {
  margin: 0px 20px;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  color: var(--navbar-text-color);
  cursor: pointer;
  margin: 0px 20px;
}

@media (max-width: 768px) {
  .nav-links {
      display: none;
      flex-direction: column;
      width: 100vw; /* Ensure it fills the entire width of the viewport */
      text-align: center;
      position: absolute; /* Position the dropdown absolutely */
      top: 100%; /* Position it below the navbar */
      left: 0;
      background-color: var(--navbar-background-color); /* Ensure it has the same background */
      z-index: 1; /* Ensure it appears above other content */
      box-sizing: border-box; /* Include padding and border in the element's total width and height */
      padding: 0; /* Remove any padding */
      margin: 0; /* Remove any margin */
  }

  .nav-links.open {
      display: flex;
  }

  .nav-links li {
      margin: 10px 0;
  }

  .menu-toggle {
      display: block;
  }
}