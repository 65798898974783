
@import '../theme.css';

  .projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .project-card {
    background-color: var(--card-background-color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px var(--card-shadow-color);
    width: 300px;
    text-align: left;
  }
  
  .project-card h3 {
    margin-top: 0;
    color: var(--content-text-color);
  }
  
  .project-card p {
    margin: 10px 0;
    color: var(--content-subtext-color);
  }
  
  .project-card a {
    color: var(--content-highlight-color);
    text-decoration: none;
  }
  
  .project-card a:hover {
    text-decoration: underline;
  }
