@import '../theme.css';

  .section {
    padding: 30px 20px;
  }
  
  .section h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: var(--content-text-color);
  }
  
  .section p {
    font-size: 16px;
    margin-bottom: 20px;
    color: var(--content-subtext-color);
  }