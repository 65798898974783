@import '../theme.css';

  .contact {
    padding: 0px 20px;
  }
  
  .contact h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: var(--content-text-color);
  }