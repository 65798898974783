@import '../theme.css';

  .footer {
    background-color: var(--navbar-background-color);
    color: white;
    padding: 10px 0;
  }

  .footer p {
    color: white;
  }

  .footer a {
    color: rgb(156, 156, 156);
  }